.api-loader-logo-wrapp {
  position: fixed;
  z-index: 1080;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .api-loader-shadow {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .api-loader-logo {
      border-radius: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 1050;
      width: 100px;
      height: 100px;

      svg.app-art-logo {
        width: 100px;
        height: 100px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1050;
        transform: translate(-50%, -50%);

        #red,
        #blue {
          mix-blend-mode: color-burn;
        }

        #blue {
          animation-duration: 2400ms;
          animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
          animation-delay: 400ms;
          animation-iteration-count: infinite;
          animation-direction: normal;
          animation-fill-mode: none;
          animation-play-state: running;
          animation-name: motionpathblue;
        }

        #red {
          animation-duration: 2400ms;
          animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
          animation-delay: 200ms;
          animation-iteration-count: infinite;
          animation-direction: normal;
          animation-fill-mode: none;
          animation-play-state: running;
          animation-name: motionpathred;
        }
      }
    }
  }
}

@keyframes motionpathblue {
  0% {
    transform: rotate(0deg);
    transform-origin: center;
  }

  100% {
    transform: rotate(1440deg);
    transform-origin: center;
  }
}

@keyframes motionpathred {
  0% {
    transform: rotate(0deg);
    transform-origin: center;
  }

  100% {
    transform: rotate(1440deg);
    transform-origin: center;
  }
}
