@import '../theme';

.btn-light {
  border: 1px solid #ced4da;
}

.dropdown-item:hover {
  color: inherit;
}

button:focus {
  outline: 0;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border: none;
}

.icon-dd {
  color: #b7b7b7;
}

.btn-link {
  font-weight: 700;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  color: $darkblue;

  &:hover,
  &:active,
  &:focus {
    color: $darkblue;
  }
}

.btn-link-clean,
.btn-link-clean:hover,
.btn-link-clean:focus {
  text-decoration: none;
}

.btn-close {
  color: $red;
  width: auto;
  height: auto;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100;
  position: absolute;
  top: -12px;
  right: -12px;
  opacity: 1;
  background: transparent;

  .feather {
    fill: $red;
    stroke: #fff;
    width: 28px;
    height: 28px;

    circle {
      stroke: $red;
    }
  }
}

.btn-delete {
  color: #fff;

  .feather {
    fill: $red;
    stroke: #fff;
    width: 24px;
    height: 24px;
  }
}

// .btn-darkprimary {
//   &.btn-sm {
//     box-shadow: -2px 2px 6px 0px rgba(0, 173, 238, 0.5);
//   }
// }

// .btn-primary {
//   &.btn-sm {
//     box-shadow: -2px 2px 6px 0px rgba(0, 173, 238, 0.5);
//   }
// }

.btn-secondary {
  color: #fff;

  &:hover {
    color: #fff;
  }

  &:active {
    color: #fff;
  }

  &.btn-sm {
    box-shadow: -2px 2px 6px 0px rgba(121, 121, 121, 0.5);
  }
}

.btn-darkprimary:not(:disabled):not(.disabled):active,
.btn-darkprimary:not(:disabled):not(.disabled).active,
.show > .btn-darkprimary.dropdown-toggle {
  background-color: $darkblue;
  border-color: $darkblue;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: $blue;
  border-color: $blue;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  box-shadow: none;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  box-shadow: none;
}

.btn-darkprimary:not(:disabled):not(.disabled):active:focus,
.btn-darkprimary:not(:disabled):not(.disabled).active:focus,
.show > .btn-darkprimary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-darkprimary.active:focus,
.btn-darkprimary:focus {
  box-shadow: none !important;
}

.btn-icon {
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
}

.btn-hide {
  visibility: hidden;
  opacity: 0;
}

.btn-icon-disabled {
  color: #c3c3c3 !important;
  opacity: 1 !important;

  &:focus,
  &:hover {
    color: #c3c3c3 !important;
  }
}

.btn-icon:focus {
  box-shadow: none;
}

.btn-icon-darkprimary {
  color: $darkblue;
}

.btn-icon-darkprimary:hover {
  color: $darkblue;
}

.btn-icon-primary {
  color: $blue;
}

.btn-icon-primary:hover {
  color: $blue;
}

.btn-icon-danger {
  color: $red;
}

.btn-icon-danger:hover {
  color: $red;
}

.btn-icon-small .feather {
  width: 16px;
  height: 16px;
}

.btn-hover {
  &.btn-icon {
    &:hover {
      color: $blue;
    }
  }
}

button.text-gray:hover {
  color: #c3c3c3;
}
